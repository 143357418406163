'use client';

import { use } from 'react';

import { Dependencies } from '@/dependencies';

/**
 * This client side component is required to wrap our install function because some of the install functions are on the client side and cannot be invoked on server.
 * TODO: Create clear boundary between client side components and server side dependency installation to remove this and leverage SSR.
 */
export function AppLayout({ children }: { children: React.ReactNode }) {
  const App = use(Dependencies.app).App;
  return <App>{children}</App>;
}
